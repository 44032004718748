import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import CTA from '../components/CTA'
import advertising from '../images/Advertise.png'
export default () => (
    <div id="app">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Advertise With Us Page"></meta>
          <title>Advertise with WRAL | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/advertise-with-us/" />
        </Helmet>
      <PreHeader/>
      <Header/>
      <h1 className="advertising--title">Advertise With Us</h1>
      <div className="advertising">
        <div className = "advertising_wrapper">
          <img src={advertising} className="advertising--image" alt="advertising mobile mockups"/>
          <div className = "advertising_grid">
            <div className= "advertising_block">
              <h3 className="advertising_block--title">Targeted Display</h3>
              <p className="advertising_block--text">Get in front of our vast audience and leverage our 1st party data to demo, interest, contextual, or retarget your audience</p>
            </div>
            <div className= "advertising_block">
              <h3 className="advertising_block--title">Sponsorships</h3>
              <p className="advertising_block--text">From weather sponsorships, to app sponsorships, to event sponsorships, we have numerous opportunities to connect your brand with our loyal audiences in a meaningful way.</p>
            </div>
            <div className= "advertising_block">
              <h3 className="advertising_block--title">Newsletters and Emails</h3>
              <p className="advertising_block--text">Whether you’re tapping into our established newsletters or sending targeted email blasts to our list of 115K+ subscribers, we can get your message into the right inboxes. </p>
            </div>
            <div className= "advertising_block">
              <h3 className="advertising_block--title">Video Advertising</h3>
              <p className="advertising_block--text">Serve your video ads in front or along side engaging news and local content. We offer pre-roll, in-read, live stream, and in-banner video options. </p>
            </div>
            <div className= "advertising_block">
              <h3 className="advertising_block--title">Social Media</h3>
              <p className="advertising_block--text">We can get your message out using our branded social channels through boosted sponsored posts on platforms like Facebook, LinkedIn, and Instagram. </p>
            </div>
            <div className= "advertising_block">
              <h3 className="advertising_block--title">Rich Media</h3>
              <p className="advertising_block--text">We can create premium and engaging ad units with great visibility across our websites and apps. </p>
            </div>
            <div className= "advertising_block">
              <h3 className="advertising_block--title">Branded Content / Spotlight</h3>
              <p className="advertising_block--text">Our skilled team works with you to develop engaging and informative content designed for our audience establishing you as a credible subject matter expert.</p>
            </div>
            <div className= "advertising_block">
              <h3 className="advertising_block--title">Contests and Polling</h3>
              <p className="advertising_block--text">Gather qualified leads and collect valuable data to help build your email database and learn more about your customers and prospects.                                                                  </p>
            </div>
            <div className= "advertising_block">
              <h3 className="advertising_block--title">Over the Top</h3>
              <p className="advertising_block--text">Target your video ad on WRAL’s OTT apps available on Roku, Amazon Fire, and Apple TV. This tactic allows you to reach our audience of cord-cutters and cord-nevers who stream WRAL content, news, and videos on demand. </p>
            </div>
          </div>
        </div>

        <div className="advertising_banner">
            <h3 className="advertising_banner-text">
              To get started today <a href="tel:+1-919-744-3030">call</a> or fill out our contact form.
            </h3>
          <div className="advertising_cta">
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--mobile"/>
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--desktop"/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
